import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  Grid,
  Modal,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CommonTable1 from "app/components/Tables/CommonTable1";
import DialogHeader from "app/components/common/DialogHeader";
import { format } from "date-fns";
import { useMutation, useQuery } from "react-query";
import {
  deleteExpense,
  getAllExpense,
  getAllExpenseByDocId,
  getAllExpenseHead,
  getAllExpenseSubHead,
  verifyPaidExpense,
} from "app/services/ledgerAccount";
import { toast } from "react-toastify";
import {
  selectActiveSession,
  selectSelectedSchool,
} from "app/modules/schools/selectors";
import { useSelector } from "react-redux";

import { LoadingButton } from "@mui/lab";
import { queryClient } from "App";
import { IndianCurrency, getPaymentDetailsString } from "app/utils/helper";
import CustomLoader from "app/components/common/CustomLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpensesDialog from "./ExpensesDialog";
import dayjs from "dayjs";
import { DATE_FORMAT } from "app/config/constants";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import { useSearchParams } from "react-router-dom";
import ExpenseFilter from "app/components/common/Dialogs/ledger/expense/ExpenseFilter";
import ApproveRejectDialog from "app/components/common/Dialogs/ledger/expense/ApproveRejectDialog";
import PayExpenseDialog from "app/components/common/Dialogs/ledger/expense/PayExpenseDialog";
import ExportDialog2 from "app/components/common/exportDialog/index2";
import PaymentSummaryComponent from "app/components/common/PaymentSummaryComponent";
import { useReactToPrint } from "react-to-print";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  outline: "none",
};

const ExpensesPage = () => {
  const selectedSession = useSelector(selectActiveSession);
  const expensePrintRef = useRef(null);

  const [getSearchParams] = useSearchParams();
  const formatDate = (data) => {
    const date = new Date(data);
    return format(date, "dd-MMM-yyyy");
  };
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [expenseDocId, setExpenseDocId] = useState("");
  const [paymentId, setpaymentId] = useState("");

  const handleCloseVerifiedDialog = () => {
    setIsVerified(false);
  };
  const handlePrint = useReactToPrint({
    content: () => expensePrintRef.current,
  });

  const [state, setState] = useState({
    tableData: [],
    heads: [],
    filteredData: false,
    fetch: false,
    active: {},
    t_CashMode: 0,
    t_NEFTMode: 0,
    t_UpiMode: 0,
    t_ChequeMode: 0,
    t_CardMode: 0,
  });

  const [dialogs, setDialogs] = useState({
    add: false,
    view: false,
    ar: false,
    pay: false,
    expenseDocId: "",
  });

  const [modals, setModals] = useState({
    delete: false,
  });

  const [checkboxes, setCheckboxes] = useState({
    expense: false,
    created: false,
    approved: false,
    head: false,
    vehicle: false,
    name: false,
  });

  const dt = new Date();
  const [filters, setFilters] = useState({
    expense: {
      from: dt,
      to: dt,
    },
    created: {
      from: dt,
      to: dt,
    },
    approved: {
      from: dt,
      to: dt,
    },
    head: {
      head: "",
      subHead: "",
    },
    vehicle: "",
    name: {
      radio: "Staff",
      list: [],
    },
  });

  const columns = [
    {
      header: "Sr.No",

      Cell: ({
        cell: {
          row: { id },
        },
      }) => (
        <Typography>
          {parseInt(id) + 1 < 10 ? `0${parseInt(id) + 1}` : parseInt(id) + 1}
        </Typography>
      ),
      maxSize: 10,
    },
    {
      header: "Expense Head",
      accessorKey: "expenseHeadName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.expenseHeadName ?? "--"}</Typography>;
      },
      maxSize: 30,
    },
    {
      header: "Sub Head Name",
      accessorKey: "expenseSubHeadName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.expenseSubHeadName ?? "--"}</Typography>;
      },
      maxSize: 30,
    },
    {
      header: "Recipient Person",
      accessorKey: "recipientPerson.personName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>
            {original?.recipientPerson?.personName ?? "--"}
          </Typography>
        );
      },
      maxSize: 60,
    },
    {
      header: "Expense Date",
      accessorKey: "expenseDate",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>
            {original.expenseDate
              ? dayjs(original.expenseDate).format(DATE_FORMAT.withMonthName)
              : "--"}
          </Typography>
        );
      },
      maxSize: 20,
    },
    {
      header: "Amount",
      accessorKey: "paidAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Box>
            {original?.requestedAmount && (
              <p
                style={{
                  color: "rgba(236, 168, 32, 1)",
                  marginBottom: "3px",
                  cursor: "default",
                }}
                title="Requested Amount"
              >
                {IndianCurrency(original.requestedAmount)}
              </p>
            )}
            {original?.approvedAmount && (
              <p
                style={{
                  color: "rgba(91, 206, 0, 1)",
                  marginBottom: "3px",
                  cursor: "default",
                }}
                title="Approved Amount"
              >
                {IndianCurrency(original.approvedAmount)}
              </p>
            )}
            {original?.paidAmount && (
              <p
                style={{
                  color: "rgba(39, 137, 253, 1)",
                  marginBottom: "3px",
                  cursor: "default",
                }}
                title="Paid Amount"
              >
                {IndianCurrency(original.paidAmount)}
              </p>
            )}
          </Box>
        );
      },
      maxSize: 30,
    },
    {
      header: "Payment Mode",
      accessorKey: "paymentMode",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>
            {original?.paymentDetails
              ? Object.keys(original?.paymentDetails)[0]
              : "--"}
          </Typography>
        );
      },
      maxSize: 20,
    },
    {
      header: "Expense Number",
      accessorKey: "expenseNumber",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.expenseNumber ?? "--"}</Typography>;
      },
      maxSize: 50,
    },
    {
      header: "Status",
      accessorKey: "status",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography
            sx={{
              fontWeight: 600,
              color:
                original?.status === "APPROVED"
                  ? "rgba(91, 206, 0, 1)"
                  : original?.status === "REQUESTED"
                  ? "rgba(236, 168, 32, 1)"
                  : original?.status === "PAID"
                  ? "rgba(39, 137, 253, 1)"
                  : original?.status === "REJECTED" && "rgba(244, 59, 59, 1)",
            }}
          >
            {original?.status}
          </Typography>
        );
      },
      maxSize: 40,
    },
    {
      header: "Payment Verified?",
      accessorKey: "paymentVerified",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Stack>
            {original?.paymentVerificationStatus && (
              <Typography>Yes</Typography>
            )}
            {!original?.paymentVerificationStatus &&
              original?.status === "PAID" && (
                <Typography
                  sx={{
                    textDecorationLine: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => handleOpenVerified(original?._id)}
                >
                  NO
                </Typography>
              )}
            {!original?.paymentVerificationStatus &&
              original?.status !== "PAID" && <Typography>NO</Typography>}
          </Stack>
        );
      },
      maxSize: 25,
    },
    {
      header: "Actions",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              onClick={() => {
                switch (original?.status?.toLowerCase()) {
                  case "requested":
                    setDialogs((prev) => ({
                      ...prev,
                      ar: true,
                    }));
                    setExpenseDocId(original._id);
                    break;
                  case "approved":
                    setDialogs((prev) => ({
                      ...prev,
                      pay: true,
                    }));
                    setExpenseDocId(original._id);
                    break;
                  default:
                    setDialogs((prev) => ({
                      ...prev,
                      view: true,
                    }));
                    setExpenseDocId(original._id);
                }
              }}
              sx={{
                padding: "5px",
                cursor: "pointer",
                background: "#d4d4d4",
                borderRadius: "10px",
              }}
            >
              <VisibilityIcon />
            </Stack>
            <Stack
              sx={{
                color: "red",
                padding: "5px",
                cursor: "pointer",
                background: "#d4d4d4",
                borderRadius: "10px",
              }}
              onClick={() => {
                setModals((prev) => ({ ...prev, delete: true }));
                setState((prev) => ({ ...prev, delActive: original._id }));
              }}
            >
              <DeleteIcon />
            </Stack>
          </Stack>
        );
      },
      maxSize: 40,
    },
  ];
  let header = [
    "Expense Head",
    "Sub Head Name",
    "RecipientPerson personName",
    "Expense Date",
    "Amount",
    "Payment Mode",
    "Expense Number",
    "Status",
    "Payment Verified",
    "Vehicle Number",
    "Purpose",
    "Via/Through",
  ];
  let exportArray = state.tableData?.map((item) => {
    return {
      "Expense Head": item?.expenseHeadName ?? "--",
      "Sub Head Name": item?.expenseSubHeadName ?? "--",
      "RecipientPerson personName": item?.recipientPerson?.personName ?? "--",
      "Expense Date":
        dayjs(item.expenseDate).format(DATE_FORMAT.withMonthName) ?? "--",
      Amount: item?.requestedAmount
        ? IndianCurrency(item?.requestedAmount)
        : item?.approvedAmount
        ? IndianCurrency(item?.approvedAmount)
        : item?.paidAmount
        ? IndianCurrency(item?.paidAmount)
        : "--",
      "Payment Mode": item?.paymentDetails
        ? Object.keys(item?.paymentDetails)[0]
        : "--",
      "Expense Number": item?.expenseNumber ? item?.expenseNumber : "--",
      Status: item?.status ? item?.status : "--",
      "Payment Verified": item?.paymentVerificationStatus
        ? item?.paymentVerificationStatus
        : "--",
      "Vehicle Number": item?.vehicleNumber ? item?.vehicleNumber : "--",
      Purpose: item?.purpose ? item?.purpose : "--",
      "Via/Through": item?.viaPerson?.personName
        ? item?.viaPerson?.personName
        : "--",
    };
  });
  const handleFiltered = (payload) => {
    setState((prev) => ({
      ...prev,
      filteredData: payload,
      fetch: !prev.fetch,
    }));
  };

  const {
    mutate: verifyPaidExpenseMutate,
    isLoading: verifyPaidExpenseLoading,
  } = useMutation(verifyPaidExpense, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      getAllExpensesRefetch();
      setpaymentId("");
      setIsVerified(false);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
      setIsVerified(false);
    },
  });

  const handleOpenVerified = (data) => {
    setIsVerified(true);
    setpaymentId(data);
  };

  const {
    mutate: getAllExpenseMutateForSearch,
    isLoading: getAllExpenseMutateForSearchLoading,
  } = useMutation(getAllExpense, {
    onSuccess: (res) => {
      let tableData = res?.data?.list?.map((item) => ({
        ...item,
        paymentVerified: item.paymentVerificationStatus === true ? "YES" : "NO",
        paymentMode: item?.paymentDetails
          ? Object.keys(item?.paymentDetails)[0]
          : "--",
      }));
      setState((prev) => ({
        ...prev,
        tableData: tableData,
        t_CashMode: res?.data?.t_CashMode,
        t_NEFTMode: res?.data?.t_NEFTMode,
        t_UpiMode: res?.data?.t_UpiMode,
        t_ChequeMode: res?.data?.t_ChequeMode,
        t_CardMode: res?.data?.t_CardMode,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    isLoading: getAllExpsesLoading,
    isFetching: getAllExpsesFetching,
    refetch: getAllExpensesRefetch,
  } = useQuery({
    queryKey: ["getAllExpense", selectedSession, state.fetch],
    queryFn: () => {
      if (state.filteredData) {
        return getAllExpense({
          session: selectedSession,
          ...state.filteredData,
        });
      } else if (!getSearchParams.get("head")) {
        return getAllExpense({
          session: selectedSession,
        });
      }
    },
    onSuccess: (res) => {
      let tableData = res?.data?.list?.map((item) => ({
        ...item,
        paymentVerified: item.paymentVerificationStatus === true ? "YES" : "NO",
        paymentMode: item?.paymentDetails
          ? Object.keys(item?.paymentDetails)[0]
          : "--",
      }));
      setState((prev) => ({
        ...prev,
        tableData: tableData,
        t_CashMode: res?.data?.t_CashMode,
        t_NEFTMode: res?.data?.t_NEFTMode,
        t_UpiMode: res?.data?.t_UpiMode,
        t_ChequeMode: res?.data?.t_ChequeMode,
        t_CardMode: res?.data?.t_CardMode,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    isLoading: getExpensesByIdLoading,
    isFetching: getExpensesByIdRefetching,
  } = useQuery({
    queryKey: ["getAllExpensesById", dialogs.view],
    queryFn: () => getAllExpenseByDocId({ expenseDocId: expenseDocId }),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, active: res?.data?.obj }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: dialogs.view || dialogs.ar || dialogs.pay ? true : false,
  });

  const { mutate: deleteExpenseMutate, isLoading: deleteExpenseLoading } =
    useMutation(deleteExpense, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        setModals((prev) => ({ ...prev, delete: false }));
        queryClient.invalidateQueries({ queryKey: "getAllExpense" });
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });

  const {
    isLoading: getAllExpenseHeadLoading,
    isFetching: getAllExpenseHeadFetching,
  } = useQuery({
    queryKey: ["getAllExpenseHead"],
    queryFn: getAllExpenseHead,
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        heads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: checkboxes.head ? true : false,
  });

  const {
    isLoading: getAllExpenseSubHeadLoading,
    isFetching: getAllExpenseSubHeadFetching,
  } = useQuery({
    queryKey: ["getAllExpenseSubHead", filters.head.head],
    queryFn: () =>
      getAllExpenseSubHead({ expenseHeadDocId: filters.head.head }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        subHeads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseSubHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: filters.head.head ? true : false,
  });
  useEffect(() => {
    const search_head = getSearchParams.get("head");
    const search_subhead = getSearchParams.get("subhead");
    if (search_head && search_subhead) {
      getAllExpenseMutateForSearch({
        session: selectedSession,
        expenseHeadDocId: search_head,
        expenseSubHeadDocId: search_subhead,
      });
    }
  }, [getSearchParams]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [worksheetData, setWorksheetData] = useState([]);
  const [dataPoints, setDataPoints] = useState([]);
  const selectedSchool = useSelector(selectSelectedSchool);
  const session = useSelector(selectActiveSession);
  useEffect(() => {
    if (openCheckBox === true) {
      const tempFilteredExportArray = exportArray.map((item) => {
        const filteredItem = {};
        selectedOptions.forEach((option) => {
          filteredItem[option] = item[option];
        });
        return filteredItem;
      });

      if (Object.keys(state.filteredData).length === 0) {
        const tempWorksheetData = [
          [],
          [],
          [],
          [],
          [],
          Object.keys(tempFilteredExportArray[0] || {}).map((key) => key),
          ...tempFilteredExportArray.map((item) => Object.values(item)),
        ];
        setWorksheetData(tempWorksheetData);
        const tempDataPoints = [
          { data: `School Name: ${selectedSchool?.schoolName}`, origin: "E1" },
          { data: `Session: ${session}`, origin: "D2" },
          {
            data: `Downloaded On: ${dayjs(new Date()).format("DD-MM-YYYY")}`,
            origin: "H2",
          },
          {
            data: `Cash Mode : ${IndianCurrency(state.t_CashMode)}`,
            origin: "D4",
          },
          {
            data: `NEFT Mode : ${IndianCurrency(state.t_NEFTMode)}`,
            origin: "E4",
          },
          {
            data: `UPI Mode : ${IndianCurrency(state.t_UpiMode)}`,
            origin: "F4",
          },
          {
            data: `Cheque Mode : ${IndianCurrency(state.t_ChequeMode)}`,
            origin: "G4",
          },
          {
            data: `Card Mode : ${IndianCurrency(state.t_CardMode)}`,
            origin: "H4",
          },
        ];
        setDataPoints(tempDataPoints);
      } else {
        const tempWorksheetData = [
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          Object.keys(tempFilteredExportArray[0] || {}).map((key) => key),
          ...tempFilteredExportArray.map((item) => Object.values(item)),
        ];
        setWorksheetData(tempWorksheetData);
        const tempDataPoints = [
          { data: `School Name: ${selectedSchool?.schoolName}`, origin: "E1" },
          { data: `Session: ${session}`, origin: "D2" },
          {
            data: `Downloaded On: ${dayjs(new Date()).format("DD-MM-YYYY")}`,
            origin: "H2",
          },
          {
            data: `Cash Mode : ${IndianCurrency(state.t_CashMode)}`,
            origin: "D4",
          },
          {
            data: `NEFT Mode : ${IndianCurrency(state.t_NEFTMode)}`,
            origin: "E4",
          },
          {
            data: `UPI Mode : ${IndianCurrency(state.t_UpiMode)}`,
            origin: "F4",
          },
          {
            data: `Cheque Mode : ${IndianCurrency(state.t_ChequeMode)}`,
            origin: "G4",
          },
          {
            data: `Card Mode : ${IndianCurrency(state.t_CardMode)}`,
            origin: "H4",
          },

          //filteres data

          { data: `Expense Summary:`, origin: "E6" },

          {
            data: state.filteredData?.expenseFrom
              ? `Expense From: ${dayjs(
                  new Date(state.filteredData?.expenseFrom)
                ).format("DD-MM-YYYY")}`
              : "Expense From: --",
            origin: "D7",
          },
          {
            data: state.filteredData?.expenseTo
              ? `Expense To: ${dayjs(
                  new Date(state.filteredData?.expenseTo)
                ).format("DD-MM-YYYY")}`
              : "Expense To: --",
            origin: "H7",
          },
          {
            data: state.filteredData?.createdFrom
              ? `Expense Created From: ${dayjs(
                  new Date(state.filteredData?.createdFrom)
                ).format("DD-MM-YYYY")}`
              : "Expense Created From: --",
            origin: "D8",
          },
          {
            data: state.filteredData?.createdTo
              ? `Expense Created To: ${dayjs(
                  new Date(state.filteredData?.createdTo)
                ).format("DD-MM-YYYY")}`
              : "Expense Created To: --",
            origin: "H8",
          },
          {
            data: state.filteredData?.approvedFrom
              ? `Expense Approved From: ${dayjs(
                  new Date(state.filteredData?.approvedFrom)
                ).format("DD-MM-YYYY")}`
              : "Expense Approved From: --",
            origin: "D9",
          },
          {
            data: state.filteredData?.approvedTo
              ? `Expense Approved To: ${dayjs(
                  new Date(state.filteredData?.approvedTo)
                ).format("DD-MM-YYYY")}`
              : "Expense Approved To: --",
            origin: "H9",
          },
          {
            data: storedData2?.headName
              ? `Expense Head Name: ${storedData2?.headName}`
              : "Expense Head Name: --",
            origin: "D10",
          },
          {
            data: storedData2?.subHeadName
              ? `Expense Sub Head Name: ${storedData2?.subHeadName}`
              : "Expense Sub Head Name: --",
            origin: "H10",
          },
          {
            data: state.filteredData?.vehicleNumber
              ? `Vehicle Number: ${state.filteredData?.vehicleNumber}`
              : "Vehicle Number:: --",
            origin: "D11",
          },
        ];
        setDataPoints(tempDataPoints);
      }
    }
  }, [openCheckBox]);
  const [storedData2, setStoredData2] = useState({});
  const handleExtraData = (filteredData) => {
    setStoredData2(filteredData);
  };

  return (
    <>
      <ExportDialog2
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        fileName="Expenses"
        worksheetData={worksheetData}
        dataPoints={dataPoints}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <ExpensesDialog dialogs={dialogs} setDialogs={setDialogs} />
      <CustomLoader
        show={
          getAllExpenseMutateForSearchLoading ||
          getAllExpsesLoading ||
          getAllExpsesFetching ||
          getAllExpenseHeadLoading ||
          getAllExpenseHeadFetching ||
          getAllExpenseSubHeadLoading ||
          getAllExpenseSubHeadFetching
        }
      />
      {/* payment verification dialog */}
      <Dialog
        onClose={handleCloseVerifiedDialog}
        open={isVerified}
        PaperProps={{
          sx: { minWidth: "25vw" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Payment Verified ? No"
            handleClose={handleCloseVerifiedDialog}
          />
        </DialogTitle>
        <DialogContent sx={{ m: 2 }}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: "16px",
              pt: 2,
              pb: 1,
            }}
          >
            Payment Verified ? NO{" "}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: "16px",
              pb: 1.5,
            }}
          >
            Are you sure you want to verify the Payment?
          </Typography>
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ width: "40%" }}
              onClick={handleCloseVerifiedDialog}
            >
              NO
            </Button>
            <LoadingButton
              loading={verifyPaidExpenseLoading}
              variant="contained"
              color="secondary"
              size="large"
              sx={{ width: "40%" }}
              onClick={() => verifyPaidExpenseMutate(paymentId)}
            >
              YES
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>

      {/* delete confirmation modal */}
      <Modal
        open={modals.delete}
        onClose={() => setModals((prev) => ({ ...prev, delete: true }))}
      >
        <Stack sx={{ ...modalStyle, width: 350 }}>
          <Stack padding={2} direction="column" spacing={1}>
            <Stack alignItems="center" justifyContent="center">
              <AccountCircleIcon
                sx={{
                  fontSize: "8rem",
                  color: "#36588b",
                }}
              />
            </Stack>

            <Stack fontSize={30} alignItems="center" justifyContent="center">
              <Typography sx={{ fontSize: "16px" }}>Are you sure to</Typography>
              <Typography sx={{ fontSize: "16px" }}>
                <span style={{ color: "#F10F0F", fontWeight: "700" }}>
                  Delete
                </span>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() =>
                  setModals((prev) => ({ ...prev, delete: false }))
                }
              >
                Cancel
              </Button>
              <LoadingButton
                loading={deleteExpenseLoading}
                onClick={() => deleteExpenseMutate(state?.delActive)}
                variant="contained"
                color="secondary"
                size="large"
              >
                Confirm
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
      {/* View dialog */}
      <Dialog
        open={dialogs?.view}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, view: false }));
          setExpenseDocId("");
        }}
        PaperProps={{
          sx: { minWidth: { xs: "95%", md: 500, lg: 700 } },
        }}
      >
        <DialogHeader
          title="View Expense"
          handleClose={() => {
            setDialogs((prev) => ({ ...prev, view: false }));
            setExpenseDocId("");
          }}
        />
        {(getExpensesByIdLoading || getExpensesByIdRefetching) && (
          <Stack direction="row" py={2} justifyContent="center">
            <CircularProgress size={35} />
          </Stack>
        )}
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "5rem", m: 2 }}
          ref={expensePrintRef}
        >
          <Stack direction="column" gap={2}>
            <Stack direction="row" gap={2}>
              <Stack flex={1}>
                <Label>Expense Number</Label>
                <Content>{state?.active?.expenseNumber || "--"}</Content>
              </Stack>
              <Stack flex={1}>
                <Label>Expense Head Name</Label>
                <Content>{state?.active?.expenseHeadName || "--"}</Content>
              </Stack>
              <Stack flex={1}>
                <Label>Sub Head Name</Label>
                <Content>{state?.active?.expenseSubHeadName || "--"}</Content>
              </Stack>
              <Stack flex={1}>
                <Label>Recipient Person Name</Label>
                <Content>
                  {state?.active?.recipientPerson?.personName || "--"}
                </Content>
              </Stack>
              <Stack flex={1}>
                <Label>Requested Amount</Label>
                <Content>{state?.active?.requestedAmount || "--"}</Content>
              </Stack>
            </Stack>
            <Stack direction="row" gap={2}>
              <Stack flex={0.3}>
                <Label>Purpose</Label>
                <Content>{state?.active?.purpose || "--"}</Content>
              </Stack>
              {state?.active?.vehicleNumber && (
                <Stack flex={1}>
                  <Label>Vehicle Number</Label>
                  <Content>{state?.active?.vehicleNumber || "--"}</Content>
                </Stack>
              )}
              {state?.active?.viaPerson?.personName && (
                <Stack flex={1}>
                  <Label>Via / Through</Label>
                  <Content>
                    {state?.active?.viaPerson?.personName || "--"}
                  </Content>
                </Stack>
              )}
            </Stack>
            {/* <Stack direction="row" gap={5}>
                  <Stack>
                    <Label>Approval Amount</Label>
                    <Content>{IndianCurrency(state?.active?.approvedAmount)}</Content>
                  </Stack>
                  <Stack>
                    <Label>Approval/Rejection Remarks</Label>
                    <Content>{state?.active?.remarks || '--'}</Content>
                  </Stack>
                </Stack> */}
            <Grid container>
              <Grid item xs={3}>
                <Label>Approval Amount</Label>
                <Content>
                  {IndianCurrency(state?.active?.approvedAmount)}
                </Content>
              </Grid>
              <Grid item xs={3}>
                <Label>Approval/Rejection Remarks</Label>
                <Content>{state?.active?.remarks || "--"}</Content>
              </Grid>
            </Grid>
            {/* <Stack direction="row" gap={5}>
                  <Stack>
                    <Label>Expense Amount</Label>
                    <Content>{IndianCurrency(state?.active?.expenseAmount)}</Content>
                  </Stack>
                  <Stack>
                    <Label>Expended on</Label>
                    <Content>{state?.active?.expenseDate ? formatDate(state?.active?.expenseDate) : '--'}</Content>
                  </Stack>
                  <Stack>
                    <Label>Attachment</Label>
                    <a href="#" style={{ color: "#1d8ba7" }}>test.pdf</a>
                  </Stack>
                </Stack> */}
            <Grid container>
              <Grid item xs={3}>
                <Label>Expense Amount</Label>
                <Content>{IndianCurrency(state?.active?.paidAmount)}</Content>
              </Grid>
              <Grid item xs={3}>
                <Label>Expended on</Label>
                <Content>
                  {state?.active?.expenseDate
                    ? formatDate(state?.active?.expenseDate)
                    : "--"}
                </Content>
              </Grid>
              <Grid item xs={3}>
                <Label>Attachment</Label>
                {state?.active?.attachmentUrl &&
                state?.active?.attachmentUrl !==
                  "https://ekalsutra.s3.ap-south-1.amazonaws.com/public/expense/undefined" ? (
                  <>
                    <a
                      href={state?.active?.attachmentUrl}
                      style={{ color: "blue", cursor: "pointer" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      view
                    </a>
                  </>
                ) : (
                  "--"
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={3}>
                <Label>Payment Mode</Label>
                <Content>
                  {state?.active?.paymentDetails
                    ? Object.keys(state?.active?.paymentDetails)[0]
                    : "--"}
                </Content>
              </Grid>
              <Grid item xs={9}>
                <Label>Payment Reference</Label>
                <Content>
                  {state?.active?.paymentDetails
                    ? getPaymentDetailsString(state?.active?.paymentDetails)
                    : "--"}
                </Content>
              </Grid>
            </Grid>
          </Stack>
          <Stack direction="row" gap={2}>
            <Stack flex={1}>
              <Label>
                Created By:{" "}
                <span>{state?.active?.createdBy?.personName || "--"}</span>
              </Label>
              <Label>
                Created On:{" "}
                <span>
                  {state?.active?.createdOn
                    ? formatDate(state?.active?.createdOn)
                    : "--"}
                </span>
              </Label>
            </Stack>
            <Stack flex={1}>
              <Label>
                Action Taken By:{" "}
                <span>{state?.active?.actionTakenBy?.personName || "--"}</span>
              </Label>
              <Label>
                Action Taken On:{" "}
                <span>
                  {state?.active?.actionTakenOn
                    ? formatDate(state?.active?.actionTakenOn)
                    : "--"}
                </span>
              </Label>
            </Stack>
            <Stack flex={1}>
              <Label>
                Paid By:{" "}
                <span>
                  {state?.active?.paymentVerificationDoneBy?.personName || "--"}
                </span>
              </Label>
              <Label>
                Paid On:{" "}
                <span>
                  {state?.active?.paymentVerificationDoneOn
                    ? formatDate(state?.active?.paymentVerificationDoneOn)
                    : "--"}
                </span>
              </Label>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handlePrint();
            }}
            color="secondary"
            variant="contained"
          >
            PRINT
          </Button>
        </DialogActions>
      </Dialog>
      {/* approve or reject dialog */}
      <ApproveRejectDialog
        open={dialogs?.ar}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, ar: false }));
        }}
        data={state?.active}
        refetch={getAllExpensesRefetch}
      />
      {/* pay dialog */}
      <PayExpenseDialog
        open={dialogs?.pay}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, pay: false }));
        }}
        data={state?.active}
        refetch={getAllExpensesRefetch}
      />

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ExpenseFilter mutate={handleFiltered} extraData={handleExtraData} />
          <Stack direction="row" gap={2} alignItems="center">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              sx={{ height: "4rem", fontSize: "1.2rem" }}
              onClick={() => {
                setDialogs((prev) => ({ ...prev, add: true }));
              }}
            >
              + Add New
            </Button>
            <ExportButtonGroup
              handleClickExcel={() => setOpenCheckBox(true)}
              showPdf={false}
            />
          </Stack>
        </Stack>
        <PaymentSummaryComponent
          showUpperSection={true}
          showLowerSection={false}
          t_CashMode={state.t_CashMode}
          t_NEFTMode={state.t_NEFTMode}
          t_UpiMode={state.t_UpiMode}
          t_ChequeMode={state.t_ChequeMode}
          t_CardMode={state.t_CardMode}
        />

        <Stack mt={3}>
          <CommonTable1
            columns={columns}
            data={state.tableData}
            maxHeight="73vh"
          />
        </Stack>
      </Container>
    </>
  );
};

export default ExpensesPage;

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 500,
  color: "#0C2F49",
}));

const Content = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  fontWeight: 700,
  color: "#0C2F49",
}));
